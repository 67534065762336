import * as React from "react";
import Layout from "../../components/layout";

// styles

// data

// markup
const SupportDevicePage = () => {
  return (
    <Layout
      title="推奨環境 | CO Record &amp; journal"
      policyStyle={true}
      showHeader={true}
      isTop={false}
    >
      <main id="id-main" className="policy-body">
        <section>
          <div className="contents">
            <div className="text-box">
              <br />
              <br />
              <br />

              <h3>推奨環境</h3>
              <p>
                ■Android 版<br />
                Android 8 以上
                <br />
                解像度 720x1280 ピクセル (タブレットには最適化されていません)
                <br />
                ネットワーク環境 (Wi-Fi/3G/4G/5G)
                <br />
                GPS、位置情報サービス
                <br />
                Root 化した端末はサポート対象外
                <br />
                ■iOS 版<br />
                iPhone 8 以上
                <br />
                iOS 14 以上
                <br />
                ネットワーク環境 (Wi-Fi/3G/4G/5G)
                <br />
                GPS、位置情報サービス
                <br />
                ジェイルブレイク (脱獄) 端末はサポート対象外
              </p>
              <p>
                注意事項：
                <br />
                CO を最適にプレイするには、2GB 以上の RAM
                を搭載した端末を使用することをお勧めします。CO
                を利用している際、端末がクラッシュしたり遅延が発生するなどの問題が頻繁に発生する場合は、次のトラブルシューティング手順をお試しください。
                <br />
                ・利用している最中は、CO 以外のアプリを閉じる
                <br />
                ・端末が利用可能な最新のオペレーティングシステム (Android、iOS
                など) を使用する
              </p>
              <p>
                データネットワーク接続 (3G/4G/5G)
                が組み込まれていない端末では、利用に必要な GPS
                信号を維持できない場合があります。
              </p>
              <p>
                iOS または Android
                のベータ版を使用している端末では、アプリが動作しない場合があります。
              </p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default SupportDevicePage;
